import $ from 'jquery';
import 'what-input';
import AOS from 'aos';
import SlickCarousel from 'slick-carousel';

// Foundation JS relies on a global varaible. In ES6, all imports are hoisted
// to the top of the file so if we used`import` to import Foundation,
// it would execute earlier than we have assigned the global variable.
// This is why we have to use CommonJS require() here since it doesn't
// have the hoisting behavior.
window.jQuery = $;
require('foundation-sites');

// If you want to pick and choose which modules to include, comment out the above and uncomment
// the line below
//import './lib/foundation-explicit-pieces';



$(document).foundation();

$(window).on('load', function () { // makes sure the whole site is loaded 
    $('#preloader').delay(150).fadeOut('slow'); // will fade out the white DIV that covers the website. 
})

AOS.init();

// STICKY HEADER
$(window).scroll(function () {
    var $this = $(this), pos = $this.scrollTop();
    if (pos > 50) {
        $('.tg-header').addClass('nav-small');
    } else {
        $('.tg-header').removeClass('nav-small');
    }
    if (pos > 50) {
        $('.tg-header').addClass('sticky_animate');
    } else {
        $('.tg-header').removeClass('sticky_animate');
    }
});

// NAVIGATION MENU SCRIPT
$(".btn-menu").click(function () {
    $('.tg-navigation').addClass('tg-nav-open');
});
$(".btn-menu-close").click(function () {
    $('.tg-navigation').removeClass('tg-nav-open');
});


// BACK TO TOP
var btn = $('#tg-backtotop');

$(window).scroll(function () {
    if ($(window).scrollTop() > 300) {
        btn.addClass('show');
    } else {
        btn.removeClass('show');
    }
});

btn.on('click', function (e) {
    e.preventDefault();
    $('html, body').animate({ scrollTop: 0 }, '300');
});

// SINGLE PAGE SCROLL
// Select all links with hashes
$('a[href*="#"]')
    // Remove links that don't actually link to anything
    .not('[href="#"]')
    .not('[href="#0"]')
    .not('[href="#panel1"]')
    .not('[href="#panel2"]')
    .not('[href="#panel3"]')
    .not('[href="#panel1v"]')
    .not('[href="#panel2v"]')
    .not('[href="#panel3v"]')
    .not('[href="#panel4v"]')
    .not('[href="#panel5v"]')
    .not('[href="#panel6v"]')
    .not('[href="#panel7v"]')
    .click(function (event) {
        // On-page links
        if (
            location.pathname.replace(/^\//, '') == this.pathname.replace(/^\//, '')
            &&
            location.hostname == this.hostname
        ) {
            // Figure out element to scroll to
            var target = $(this.hash);
            target = target.length ? target : $('[name=' + this.hash.slice(1) + ']');
            // Does a scroll target exist?
            if (target.length) {
                // Only prevent default if animation is actually gonna happen
                event.preventDefault();
                $('html, body').animate({
                    scrollTop: target.offset().top
                }, 1000, function () {
                    // Callback after animation
                    // Must change focus!
                    var $target = $(target);
                    $target.focus();
                    if ($target.is(":focus")) { // Checking if the target was focused
                        return false;
                    } else {
                        $target.attr('tabindex', '-1'); // Adding tabindex for elements not focusable
                        $target.focus(); // Set focus again
                    };
                });
            }
        }
    });


// WHAT WE DO SLIDER
$('.tg-serviceslider').slick({
    dots: true,
    infinite: false,
    arrows: false,
    speed: 300,
    slidesToShow: 5,
    slidesToScroll: 1,
    responsive: [
        {
            breakpoint: 1600,
            settings: {
                slidesToShow: 3,
                slidesToScroll: 1,
            }
        },
        {
            breakpoint: 1366,
            settings: {
                slidesToShow: 3,
                slidesToScroll: 1,
            }
        },
        {
            breakpoint: 1024,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 1,
            }
        },
        {
            breakpoint: 600,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1
            }
        },
        {
            breakpoint: 480,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1
            }
        }
        // You can unslick at a given breakpoint now by adding:
        // settings: "unslick"
        // instead of a settings object
    ]
});

// TESTIMONIAL SLIDER
$('.tg-testi-slider').slick({
    dots: false,
    arrows: true,
    infinite: false,
    speed: 300,
    slidesToShow: 1,
    slidesToScroll: 1,
});

$('[data-app-dashboard-toggle-shrink]').on('click', function (e) {
    e.preventDefault();
    $(this).parents('.app-dashboard').toggleClass('shrink-medium').toggleClass('shrink-large');
});